import React from 'react';
import './Footer.css'; // Adjust the path if necessary
import heartImage from './images/the-heart.png'; // Adjust the path if necessary

const Footer = ({ className }) => {
    return (
      <footer className={`footer ${className}`}>
        MADE WITH
        <img src={heartImage} alt="love" className="heart" />
        BY DAILEY, ILLUMINATED BY <span className="shimmer"> <a href='https://www.thecuriouscreative.net'>THE CURIOUS CREATIVE.</a></span>
      </footer>
    );
  };
  
  export default Footer;