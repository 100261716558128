import React, { useState, useEffect, useRef } from 'react';
import icon from './images/icons/icon-blue.png';
import logo from './images/logo/Logo-lightblue-transparent.png';
import './App.css';
import Footer from './Footer.js';

function App() {
  const [showFooter, setShowFooter] = useState(false); // Initial state for footer visibility
  const [position, setPosition] = useState({ top: window.innerHeight / 2 - 50, left: window.innerWidth / 2 });
  const [velocity, setVelocity] = useState({ x: 0, y: 0 });
  const [isShooting, setIsShooting] = useState(false); // Controls whether the icon is shooting
  const [showText, setShowText] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isShaking, setIsShaking] = useState(false);
  const [sparks, setSparks] = useState([]);
  const [bgColor, setBgColor] = useState('#C2E8F7');
  const iconRef = useRef(null);

  const gravity = 0.6;
  const bounceFactor = 0.6;
  const friction = 0.95;

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);



  const interpolateColor = (distance) => {
    const colorStops = [
      { distance: 200, color: '#C2E8F7' },
      { distance: 100, color: '#73D1F6' },
      { distance: 50, color: '#0093CB' },
      { distance: 0, color: '#0093CB' }
    ];

    const blendColors = (color1, color2, ratio) => {
      const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
      };
      const rgbToHex = (r, g, b) => {
        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
      };

      const [r1, g1, b1] = hexToRgb(color1);
      const [r2, g2, b2] = hexToRgb(color2);

      const r = Math.round(r1 * ratio + r2 * (1 - ratio));
      const g = Math.round(g1 * ratio + g2 * (1 - ratio));
      const b = Math.round(b1 * ratio + b2 * (1 - ratio));

      return rgbToHex(r, g, b);
    };

    for (let i = 0; i < colorStops.length - 1; i++) {
      const start = colorStops[i];
      const end = colorStops[i + 1];
      if (distance <= start.distance && distance >= end.distance) {
        const ratio = (distance - end.distance) / (start.distance - end.distance);
        return blendColors(start.color, end.color, ratio);
      }
    }
    return colorStops[colorStops.length - 1].color;
  };
  // Show footer 1 second after shooting starts
  useEffect(() => {
    if (isShooting) {
      const timer = setTimeout(() => {
        setShowFooter(true); // Show footer after 1 second
        console.log('Footer should now be showing.'); // Debugging log
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isShooting]);

  const generateSpark = () => {
    const id = Math.random();
    const initialX = position.left + (Math.random() - 0.5) * 100;
    const initialY = position.top + (Math.random() - 0.5) * 50;
    const initialVelocityX = (Math.random() - 0.5) * 10;
    const initialVelocityY = -Math.random() * 10;

    const sparkColors = ['#C2E8F7', '#73D1F6', '#0093CB', '#315FAC'];
    const randomColor = sparkColors[Math.floor(Math.random() * sparkColors.length)];

    return {
      id,
      x: initialX,
      y: initialY,
      velocityX: initialVelocityX,
      velocityY: initialVelocityY,
      opacity: 1,
      size: Math.random() * 10 + 5,
      color: randomColor,
    };
  };

  // Only generate sparks on mobile or when shaking on desktop, and stop when `isShooting` is true
  useEffect(() => {
    if (!isShooting && (isMobile || isShaking)) {
      const interval = setInterval(() => {
        setSparks((prevSparks) => [...prevSparks, generateSpark()]);
      }, 100);

      return () => clearInterval(interval);
    }
  }, [isShooting, isShaking, isMobile]);

  // Move sparks with physics
  useEffect(() => {
    const moveSparks = () => {
      setSparks((prevSparks) =>
        prevSparks
          .map((spark) => {
            let newVelocityY = spark.velocityY + gravity;
            let newVelocityX = spark.velocityX * friction;
            let newY = spark.y + newVelocityY;
            let newX = spark.x + newVelocityX;

            if (newY + spark.size >= window.innerHeight) {
              newY = window.innerHeight - spark.size;
              newVelocityY = -newVelocityY * bounceFactor;
            }

            if (newX + spark.size >= window.innerWidth || newX <= 0) {
              newVelocityX = -newVelocityX * bounceFactor;
            }

            return {
              ...spark,
              x: newX,
              y: newY,
              velocityX: newVelocityX,
              velocityY: newVelocityY,
              opacity: spark.opacity - 0.02,
            };
          })
          .filter((spark) => spark.opacity > 0)
      );
    };

    const interval = setInterval(moveSparks, 16);
    return () => clearInterval(interval);
  }, []);

  const handleBallShootOff = (e) => {
    if (!isShooting) {
      const ballElement = iconRef.current;
      const ballRect = ballElement.getBoundingClientRect();

      const directionX = e.clientX - (ballRect.left + ballRect.width / 2);
      const directionY = e.clientY - (ballRect.top + ballRect.height / 2);
      const magnitude = Math.sqrt(directionX ** 2 + directionY ** 2);

      setVelocity({
        x: (directionX / magnitude) * 10,
        y: (directionY / magnitude) * 10,
      });

      setIsShooting(true); // This stops spark generation and starts the footer timer
      setShowText(true);
      setIsShaking(false);
      setIsVisible(false);
      setBgColor('#0093CB');
    }
  };

  const handleMouseMove = (e) => {
    if (isShooting || !isVisible) return;

    if (iconRef.current) {
      const ballRect = iconRef.current.getBoundingClientRect();
      const ballCenterX = ballRect.left + ballRect.width / 2;
      const ballCenterY = ballRect.top + ballRect.height / 2;

      const distance = Math.sqrt(Math.pow(e.clientX - ballCenterX, 2) + Math.pow(e.clientY - ballCenterY, 2));

      // Only start shaking and generate sparks if within 200px of the icon
      if (distance < 200) {
        setIsShaking(true);
        setBgColor(interpolateColor(distance));
      } else {
        setIsShaking(false);
        setBgColor('#C2E8F7');
      }
    }
  };

  return (
    <div className="App" onMouseMove={handleMouseMove} style={{ backgroundColor: bgColor }}>
      <header className="App-header">
        {showText && (
          <>
            <img src={logo} className="App-logo" alt="logo" />

            <div className="App-info">
              <p>
                At DAILEY, we build custom software, provide expert consulting, and offer hands-on training—all with a focus on exceptional customer service.
              </p>
              <p>
                Whether you need a tailored solution or guidance to streamline your business, we’ve got you covered. We serve the <span className="popover" data-content="The 'Fortune 5,000,000' is our way of celebrating the millions of small businesses that, in our opinion, make an even bigger impact—just like the Fortune 500, but with heart.">Fortune 5,000,000</span>—and we’re committed to helping you succeed.
                <br /><br />
                <a href="mailto:hello@dailey.llc">What can we do for you?</a>
              </p>
            </div>
          </>
        )}

        {sparks.map((spark) => (
          <div
            key={spark.id}
            className="spark"
            style={{
              top: spark.y,
              left: spark.x,
              width: `${spark.size}px`,
              height: `${spark.size}px`,
              backgroundColor: spark.color,
              opacity: spark.opacity,
            }}
          ></div>
        ))}

        {isVisible && (
          <img
            src={icon}
            className={`App-icon ${isShaking ? 'shaking' : ''} ${isShooting ? 'shooting' : ''}`}
            alt="icon"
            ref={iconRef}
            style={{
              top: `${position.top}px`,
              left: `${position.left}px`,
            }}
            onClick={handleBallShootOff}
          />
        )}
      </header>

      {/* Conditionally add the 'show' class to the footer */}
      <Footer className={showFooter ? 'show' : ''} />
    </div>
  );
}

export default App;